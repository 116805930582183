// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-en-js": () => import("./../../../src/pages/about.en.js" /* webpackChunkName: "component---src-pages-about-en-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-banderole-en-js": () => import("./../../../src/pages/banderole.en.js" /* webpackChunkName: "component---src-pages-banderole-en-js" */),
  "component---src-pages-banderole-js": () => import("./../../../src/pages/banderole.js" /* webpackChunkName: "component---src-pages-banderole-js" */),
  "component---src-pages-bubblebags-en-js": () => import("./../../../src/pages/bubblebags.en.js" /* webpackChunkName: "component---src-pages-bubblebags-en-js" */),
  "component---src-pages-bubblebags-js": () => import("./../../../src/pages/bubblebags.js" /* webpackChunkName: "component---src-pages-bubblebags-js" */),
  "component---src-pages-cart-en-js": () => import("./../../../src/pages/cart.en.js" /* webpackChunkName: "component---src-pages-cart-en-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-contacts-en-js": () => import("./../../../src/pages/contacts.en.js" /* webpackChunkName: "component---src-pages-contacts-en-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-ecobags-brown-en-js": () => import("./../../../src/pages/ecobags-brown.en.js" /* webpackChunkName: "component---src-pages-ecobags-brown-en-js" */),
  "component---src-pages-ecobags-brown-js": () => import("./../../../src/pages/ecobags-brown.js" /* webpackChunkName: "component---src-pages-ecobags-brown-js" */),
  "component---src-pages-ecobags-color-en-js": () => import("./../../../src/pages/ecobags-color.en.js" /* webpackChunkName: "component---src-pages-ecobags-color-en-js" */),
  "component---src-pages-ecobags-color-js": () => import("./../../../src/pages/ecobags-color.js" /* webpackChunkName: "component---src-pages-ecobags-color-js" */),
  "component---src-pages-ecobags-en-js": () => import("./../../../src/pages/ecobags.en.js" /* webpackChunkName: "component---src-pages-ecobags-en-js" */),
  "component---src-pages-ecobags-js": () => import("./../../../src/pages/ecobags.js" /* webpackChunkName: "component---src-pages-ecobags-js" */),
  "component---src-pages-ecobags-white-en-js": () => import("./../../../src/pages/ecobags-white.en.js" /* webpackChunkName: "component---src-pages-ecobags-white-en-js" */),
  "component---src-pages-ecobags-white-js": () => import("./../../../src/pages/ecobags-white.js" /* webpackChunkName: "component---src-pages-ecobags-white-js" */),
  "component---src-pages-envelopes-en-js": () => import("./../../../src/pages/envelopes.en.js" /* webpackChunkName: "component---src-pages-envelopes-en-js" */),
  "component---src-pages-envelopes-extension-en-js": () => import("./../../../src/pages/envelopes-extension.en.js" /* webpackChunkName: "component---src-pages-envelopes-extension-en-js" */),
  "component---src-pages-envelopes-extension-js": () => import("./../../../src/pages/envelopes-extension.js" /* webpackChunkName: "component---src-pages-envelopes-extension-js" */),
  "component---src-pages-envelopes-individual-en-js": () => import("./../../../src/pages/envelopes-individual.en.js" /* webpackChunkName: "component---src-pages-envelopes-individual-en-js" */),
  "component---src-pages-envelopes-individual-js": () => import("./../../../src/pages/envelopes-individual.js" /* webpackChunkName: "component---src-pages-envelopes-individual-js" */),
  "component---src-pages-envelopes-js": () => import("./../../../src/pages/envelopes.js" /* webpackChunkName: "component---src-pages-envelopes-js" */),
  "component---src-pages-envelopes-straight-en-js": () => import("./../../../src/pages/envelopes-straight.en.js" /* webpackChunkName: "component---src-pages-envelopes-straight-en-js" */),
  "component---src-pages-envelopes-straight-js": () => import("./../../../src/pages/envelopes-straight.js" /* webpackChunkName: "component---src-pages-envelopes-straight-js" */),
  "component---src-pages-envelopes-thermopack-en-js": () => import("./../../../src/pages/envelopes-thermopack.en.js" /* webpackChunkName: "component---src-pages-envelopes-thermopack-en-js" */),
  "component---src-pages-envelopes-thermopack-js": () => import("./../../../src/pages/envelopes-thermopack.js" /* webpackChunkName: "component---src-pages-envelopes-thermopack-js" */),
  "component---src-pages-envelopes-triangular-en-js": () => import("./../../../src/pages/envelopes-triangular.en.js" /* webpackChunkName: "component---src-pages-envelopes-triangular-en-js" */),
  "component---src-pages-envelopes-triangular-js": () => import("./../../../src/pages/envelopes-triangular.js" /* webpackChunkName: "component---src-pages-envelopes-triangular-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-individualform-en-js": () => import("./../../../src/pages/individualform.en.js" /* webpackChunkName: "component---src-pages-individualform-en-js" */),
  "component---src-pages-individualform-js": () => import("./../../../src/pages/individualform.js" /* webpackChunkName: "component---src-pages-individualform-js" */),
  "component---src-pages-other-en-js": () => import("./../../../src/pages/other.en.js" /* webpackChunkName: "component---src-pages-other-en-js" */),
  "component---src-pages-other-js": () => import("./../../../src/pages/other.js" /* webpackChunkName: "component---src-pages-other-js" */),
  "component---src-pages-printed-en-js": () => import("./../../../src/pages/printed.en.js" /* webpackChunkName: "component---src-pages-printed-en-js" */),
  "component---src-pages-printed-js": () => import("./../../../src/pages/printed.js" /* webpackChunkName: "component---src-pages-printed-js" */),
  "component---src-pages-printform-en-js": () => import("./../../../src/pages/printform.en.js" /* webpackChunkName: "component---src-pages-printform-en-js" */),
  "component---src-pages-printform-js": () => import("./../../../src/pages/printform.js" /* webpackChunkName: "component---src-pages-printform-js" */),
  "component---src-pages-products-en-js": () => import("./../../../src/pages/products.en.js" /* webpackChunkName: "component---src-pages-products-en-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-request-en-js": () => import("./../../../src/pages/request.en.js" /* webpackChunkName: "component---src-pages-request-en-js" */),
  "component---src-pages-request-js": () => import("./../../../src/pages/request.js" /* webpackChunkName: "component---src-pages-request-js" */),
  "component---src-pages-rtg-en-js": () => import("./../../../src/pages/rtg.en.js" /* webpackChunkName: "component---src-pages-rtg-en-js" */),
  "component---src-pages-rtg-js": () => import("./../../../src/pages/rtg.js" /* webpackChunkName: "component---src-pages-rtg-js" */),
  "component---src-pages-sale-js": () => import("./../../../src/pages/sale.js" /* webpackChunkName: "component---src-pages-sale-js" */),
  "component---src-pages-stericlin-en-js": () => import("./../../../src/pages/stericlin.en.js" /* webpackChunkName: "component---src-pages-stericlin-en-js" */),
  "component---src-pages-stericlin-js": () => import("./../../../src/pages/stericlin.js" /* webpackChunkName: "component---src-pages-stericlin-js" */),
  "component---src-pages-thanxrequest-en-js": () => import("./../../../src/pages/thanxrequest.en.js" /* webpackChunkName: "component---src-pages-thanxrequest-en-js" */),
  "component---src-pages-thanxrequest-js": () => import("./../../../src/pages/thanxrequest.js" /* webpackChunkName: "component---src-pages-thanxrequest-js" */),
  "component---src-pages-weightcalc-js": () => import("./../../../src/pages/weightcalc.js" /* webpackChunkName: "component---src-pages-weightcalc-js" */)
}

